<template>
  <LxpModal :show.sync="showModal" id="delete-initiative-modal" centered>
    <div class="di-content">
      <h5>Delete {{ initiative.title }} ?</h5>
      <p>Deleting this is permanent. <br />Are you sure you want to proceed?</p>
    </div>
    <template #footer>
      <div class="di-footer">
        <LxpButton variant="outline-primary" @click="onDelete"
          >Delete</LxpButton
        >
        <LxpButton variant="primary" @click="showModal = false"
          >Cancel</LxpButton
        >
      </div>
    </template>
  </LxpModal>
</template>

<script>
import { LxpModal, LxpButton } from "didactica";
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    initiative: {
      type: Object,
      required: true
    },
    org: {
      type: String,
      default: ""
    }
  },
  components: {
    LxpModal,
    LxpButton
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    ...mapGetters(["getOrgList"])
  },
  watch: {
    showModal(newValue) {
      this.$emit("update:show", newValue);
    },
    show() {
      this.showModal = this.show;
    }
  },
  methods: {
    onDelete() {
      this.deleteInitiative = {
        id: this.initiative.id,
        org: this.initiative.org
      };
      this.$store
        .dispatch("deleteInitiative", this.deleteInitiative)
        .then(() => {
          this.$root.$emit("initiativeDelete", {
            initiativeDeleted: true,
            message: " Initiative removed successfully. ",
            title: "Deleted ! ",
            variant: "warning"
          });
        });
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss">
#delete-initiative-modal {
  .di-content {
    text-align: center;
    h5 {
      @include subtitle-large;
      color: $brand-neutral-900;
      margin-bottom: 8px;
    }
    p {
      @include body-regular;
      margin-bottom: 0;
    }
  }
  .di-footer {
    @include horizontal-space-between;
    padding-bottom: 16px;
  }
}
</style>
