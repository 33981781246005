<template>
  <div class="lsi-card__wrapper" tabindex="0">
    <div class="lsi-card__top">
      <img :src="logo" alt="logo" height="77" />
    </div>
    <div class="lsi-card__bottom">
      <div class="lsi-card__content">
        <div class="lsi-card__title">
          <h6>{{ title }}</h6>
        </div>
        <div class="lsi-card__short-headline">
          <p>{{ subTitle }}</p>
        </div>
      </div>

      <div class="lsi-card__right--footer">
        <div class="lsi-card__right--live">
          <div
            class="lsi-card__right--live--sec"
            @click="handleClick"
            tabindex="0"
            @keydown.enter="handleClick"
          >
            <LxpLoader
              loaderClass="lsi-card__right-spinner"
              v-if="internalPublishStatus === 'Publishing'"
            />
            <img :src="getImageSrc" :alt="getImageAlt" />

            <transition name="fade">
              <span
                :key="internalPublishStatus"
                :class="
                  internalPublishStatus === 'Published' ? 'published' : ''
                "
              >
                {{ internalPublishStatus }}
              </span>
            </transition>
          </div>
        </div>
        <div
          class="lsi-card__right--footer-manage"
          id="manage"
          @click="onManageClick"
          tabindex="0"
          @keydown.enter="onManageClick"
        >
          <img src="@/assets/images/learning-space/cards/edit.svg" alt="edit" />
          <span>Manage</span>
        </div>
        <LxpMenu
          :text="menuText"
          :items="menuItems"
          className="lsi-card__right--options"
          @select="onSelect"
          :noCaret="true"
          :isMenuTextHTML="true"
        />
      </div>
    </div>
    <delete-modal
      :show.sync="showDeleteModal"
      :initiative="initiative"
    ></delete-modal>
    <UnPublishModal
      :show.sync="showUnPublishModal"
      :initiative="initiative"
      @unpublish-initiative="updateStatus"
    ></UnPublishModal>
  </div>
</template>

<script>
import { LxpMenu, LxpLoader } from "didactica";
import DeleteModal from "../Modals/DeleteModal.vue";
import UnPublishModal from "../Modals/UnPublishModal.vue";

export default {
  props: {
    org: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    initiative: {
      type: Object
    },
    publishStatus: {
      type: Boolean
    }
  },
  components: {
    LxpMenu,
    DeleteModal,
    UnPublishModal,
    LxpLoader
  },
  computed: {
    menuText() {
      return `<img src="${require("@/assets/images/learning-space/initiative/option.svg")}">`;
    },

    getImageSrc() {
      switch (this.internalPublishStatus) {
        case "Publish Live":
          return require("@/assets/images/learning-space/initiative/publish.svg");
        case "Published":
          return require("@/assets/images/learning-space/initiative/success.svg");
        case "Try Again":
          return require("@/assets/images/learning-space/initiative/retry.svg");
        case "Unpublish":
          return require("@/assets/images/learning-space/initiative/unpublish.svg");
        default:
          return "";
      }
    },
    getImageAlt() {
      switch (this.internalPublishStatus) {
        case "Publish Live":
          return "Publish Live";
        case "Published":
          return "Published";
        case "Try Again":
          return "Try Again";
        case "Unpublish":
          return "unpublish";
        default:
          return "";
      }
    }
  },
  data() {
    return {
      showDeleteModal: false,
      showPublishModal: false,
      showUnPublishModal: false,
      dismissSecs: 3,
      dismissCountDown: 0,
      errMsg: "",
      internalPublishStatus: this.publishStatus ? "Unpublish" : "Publish Live",
      menuItems: [
        {
          id: "delete",
          label: "Delete",
          active: false
        }
      ]
    };
  },
  methods: {
    onManageClick() {
      this.$router.push({
        name: "EditInitiative",
        params: { org: this.org, id: this.initiative.id }
      });
    },
    handleError(error) {
      this.errMsg = error.message;
    },
    handleClick() {
      if (
        this.internalPublishStatus === "Publish Live" ||
        this.internalPublishStatus === "Try Again"
      ) {
        this.onPublish();
      } else {
        this.onUnpublish();
      }
    },
    onSelect(e) {
      if (e.id === "delete") {
        this.showDeleteModal = true;
      }
    },
    async onPublish() {
      this.internalPublishStatus = "Publishing";
      this.publishInitiative = {
        id: this.initiative.id,
        org: this.initiative.org
      };
      this.$store
        .dispatch("publishInitiative", this.publishInitiative)
        .then(() => {
          this.$root.$emit("PublishInitiative", {
            initiativePublished: true,
            message: "Initiative published successfully. ",
            title: "Published ! ",
            variant: "success"
          });
          this.internalPublishStatus = "Published";
          setTimeout(() => {
            this.internalPublishStatus = "Unpublish";
          }, 3500);
        })
        .catch(error => {
          this.internalPublishStatus = "Try Again";
          this.$root.$emit("PublishInitiativeError", {
            errorPublishingInitiative: true,
            message:
              "There was an error publishing the initiative, Please try again. ",
            title: "Try Again ! ",
            variant: "error"
          });
          this.handleError(error);
        });
    },
    onUnpublish() {
      this.showUnPublishModal = true;
    },
    updateStatus() {
      this.internalPublishStatus = "Publish Live";
    }
  }
};
</script>

<style lang="scss">
.lsi-card__wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  width: calc(50% - 16px);
  height: 254px;
  &:hover {
    box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
  }

  .lsi-card__top {
    img {
      object-fit: cover;
      vertical-align: baseline;
      width: 100%;
      border: 1px solid $brand-neutral-75;
      border-radius: 8px;
    }
  }
  .lsi-card__bottom {
    margin-top: 16px;
    h6 {
      @include body-regular;
    }
    p {
      @include body-medium;
      color: $brand-neutral-300;
      margin-bottom: 12px;
    }
    .lsi-card__content {
      height: 90px;
    }
    .lsi-card__title {
      overflow: hidden;
      @include line-clamp($lines: 1);
      white-space: pre-wrap;
    }
    .lsi-card__short-headline {
      overflow: hidden;
      @include line-clamp($lines: 2);
      white-space: pre-wrap;
    }
    .lsi-card__right--footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include label-medium;
      .lsi-card__right--footer-manage {
        @include label-medium(500);
        padding: 5px;
        color: $brand-neutral-900;
        cursor: pointer;
        img {
          margin-right: 5px;
        }
        &:hover {
          background: $brand-primary-75;
          color: $brand-primary-400;
          border-radius: 8px;
          img {
            filter: $icon-filter-primary;
          }
        }
      }
      .lsi-card__right--options {
        margin: 0 5px 0 28px;
        .b-dropdown {
          .dropdown-toggle {
            padding: 3px 7px;
          }
        }
        &:hover {
          img {
            filter: $icon-filter-primary;
          }
        }
      }
      .lsi-card__right--live {
        margin-right: 33px;
        cursor: pointer;

        .lsi-card__right--live--sec {
          @include label-medium(500);
          padding: 5px;
          color: $brand-neutral-900;
          display: flex;
          span {
            padding-left: 6px;
          }
          &:hover {
            background: $brand-primary-75;
            color: $brand-primary-400;
            border-radius: 8px;
            img {
              filter: $icon-filter-primary;
            }
          }
        }
      }
      .lsi-card__right-spinner {
        min-height: auto;
        &:hover {
          img {
            filter: $icon-filter-primary;
          }
        }
        .spinner-border {
          &.text-primary {
            color: $brand-neutral-300 !important;
            width: 16px;
            height: 16px;
            border-right-color: $brand-primary-400 !important;
          }
        }
      }
      .published {
        color: $brand-success-400 !important;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
