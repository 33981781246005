<template>
  <div class="learning-space__list">
    <label>Main</label>
    <LearningSpaceCard
      v-if="selectedSpace"
      :logo="selectedSpace.logo"
      :title="selectedSpace.title"
      :subTitle="selectedSpace.subTitle"
      :org="selectedSpace.org"
      :onManageClick="selectedLearningSpace"
    ></LearningSpaceCard>
    <initiatives v-if="selectedSpace" :org="selectedSpace.org"></initiatives>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LearningSpaceCard from "./Cards/LearningSpaceCard.vue";
import Initiatives from "./Initiatives.vue";
export default {
  inject: ["parentRoute"],
  components: {
    LearningSpaceCard,
    Initiatives
  },

  computed: {
    ...mapGetters([
      "getSpacesList",
      "getFeaturedResourcesList",
      "getManageSectionList"
    ]),

    spacesList() {
      let spacesList = [];
      this.getSpacesList.forEach(space => {
        spacesList.push({
          logo: space.logo
            ? space.logo
            : require("@/assets/images/layout/header/whoa-logo.svg"),
          title: space.name,
          org: space.org_short_name,
          subTitle: space.about?.short_headline
        });
      });
      return spacesList;
    },
    selectedSpacesList() {
      const org = this.$route.params.org;
      return this.getSpacesList.filter(space => space.org_short_name === org);
    },
    currentOrg() {
      return this.$route.params.org;
    },
    selectedSpace() {
      const org = this.$route.params.org;
      const curSpace = this.getSpacesList.find(
        space => space.org_short_name === org
      );

      return {
        logo: curSpace.logo
          ? curSpace.logo
          : require("@/assets/images/layout/header/whoa-logo.svg"),
        title: curSpace.name,
        org: curSpace.org_short_name,
        subTitle: curSpace.about?.short_headline
      };
    }
  },

  methods: {
    selectedLearningSpace() {
      this.$router.push({
        name: "EditLearningSpace",
        params: { org: this.currentOrg }
      });
    }
  }
};
</script>

<style lang="scss">
.learning-space__list {
  label {
    @include body-regular(500);
    margin-bottom: 24px;
  }
}
</style>
