<template>
  <div class="ls-initiatives">
    <div class="ls-initiatives__header">
      <h5 class="ls-initiatives__header--title">Initiatives</h5>
      <a
        class="ls-initiatives__header--cta"
        @click="createInitiative"
        tabindex="0"
        @keydown.enter="createInitiative"
        >+ New initiative</a
      >
    </div>
    <div class="ls-initiatives__content">
      <p v-if="isLoading"><LxpLoader /></p>
      <div
        class="ls-initiatives__content--empty"
        v-else-if="initiativesList.length === 0"
      >
        <p>You have not added any initiatives pages yet.</p>
      </div>
      <div v-else class="lsi-cards-container">
        <initiatives-card
          v-for="initiative in initiativesList"
          :key="initiative.id"
          :logo="initiative.logo"
          :title="initiative.title"
          :subTitle="initiative.subTitle"
          :org="initiative.org"
          :initiative="initiative"
          :publishStatus="initiative.publishStatus"
          @delete-initiative="onDeleteInitiative"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import InitiativesCard from "./Cards/InitiativesCard.vue";
import { LxpLoader } from "didactica";
export default {
  props: {
    org: {
      type: String,
      default: "",
      required: true
    }
  },
  data() {
    return {
      isLoading: true
    };
  },
  components: {
    InitiativesCard,
    LxpLoader
  },

  computed: {
    ...mapGetters(["getInitiativesList", "getSpacesList"]),
    initiativesList() {
      let list = [];
      this.getInitiativesList
        .filter(i => i.org_short_name === this.org)
        .forEach(initiative => {
          list.push({
            id: initiative.id,
            logo: initiative.image
              ? initiative.image
              : require("@/assets/images/layout/header/whoa-logo.svg"),
            title: initiative.name,
            org: initiative.org_short_name,
            subTitle: initiative.short_headline,
            publishStatus: initiative.is_published
          });
        });
      return list;
    }
  },
  created() {
    this.listInitiatives();
  },
  methods: {
    listInitiatives() {
      this.$store
        .dispatch("listInitiatives", { org: this.org })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    createInitiative() {
      this.$router.push({
        name: "CreateInitiative",
        params: { org: this.org }
      });
    },
    onDeleteInitiative(initiativeToDelete) {
      const orgName = this.org;
      const index = this.initiativesList.findIndex(
        initiative => initiative.id === initiativeToDelete.id
      );
      if (index !== -1) {
        this.initiativesList.splice(index, 1);
      }
      this.$emit("delete-initiative", initiativeToDelete, orgName);
    }
  }
};
</script>
<style lang="scss" scoped>
.ls-initiatives {
  margin-top: 32px;
  .ls-initiatives__header {
    @include horizontal-space-between;
    .ls-initiatives__header--title {
      @include body-regular(500);
    }
    .ls-initiatives__header--cta {
      @include label-large($brand-primary-400);
      text-decoration: none;
      cursor: pointer;
      padding: 6px 12px;
      &:hover {
        background: $brand-primary-100;
        border-radius: 8px;
      }
    }
  }
  .lsi-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ls-initiatives__content {
    margin-top: 24px;
    .ls-initiatives__content--empty {
      background: #ffffff;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      border-radius: 16px;
      padding: 16px;
      p {
        @include label-large($brand-neutral-300);
        margin-bottom: 0;
      }
    }
  }
}
</style>
