<template>
  <LxpModal :show.sync="showModal" id="unpublish-modal" centered>
    <div class="di-content">
      <h5>Unpublish {{ initiative.title }} ?</h5>
      <p>
        This won’t be visible to the learners anymore.<br />Are you sure you
        want to proceed?
      </p>
    </div>
    <template #footer>
      <div class="di-footer">
        <LxpButton variant="outline-primary" @click="onUnpublish"
          >Unpublish</LxpButton
        >
        <LxpButton variant="primary" @click="showModal = false"
          >Cancel</LxpButton
        >
      </div>
    </template>
  </LxpModal>
</template>

<script>
import { LxpModal, LxpButton } from "didactica";
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    initiative: {
      type: Object,
      required: true
    },
    org: {
      type: String,
      default: ""
    }
  },
  components: {
    LxpModal,
    LxpButton
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    ...mapGetters(["getOrgList"])
  },
  watch: {
    showModal(newValue) {
      this.$emit("update:show", newValue);
    },
    show() {
      this.showModal = this.show;
    }
  },
  methods: {
    onUnpublish() {
      this.unpublishInitiative = {};
      this.unpublishInitiative.id = this.initiative.id;
      this.unpublishInitiative.org = this.initiative.org;

      this.$store
        .dispatch("unpublishInitiative", this.unpublishInitiative)
        .then(() => {
          this.$root.$emit("UnpublishInitiative", {
            initiativeUnPublished: true,
            message: "Initiative got Unpublished.  ",
            title: "Unpublished ! ",
            variant: "warning"
          });
          this.showModal = false;
          this.$emit("unpublish-initiative");
        })
        .catch(() => {
          this.$root.$emit("UnpublishInitiativeError", {
            errorUnpublishingInitiative: true,
            message:
              "There was an error Unpublishing the initiative, Please try again. ",
            title: "Try Again ! ",
            variant: "error"
          });
          this.showModal = false;
        });
    }
  }
};
</script>

<style lang="scss">
#unpublish-modal {
  .di-content {
    text-align: center;
    h5 {
      @include subtitle-large;
      color: $brand-neutral-900;
      margin-bottom: 8px;
    }
    p {
      @include body-regular;
      margin-bottom: 0;
    }
  }
  .di-footer {
    @include horizontal-space-between;
    padding-bottom: 16px;
  }
}
</style>
